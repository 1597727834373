



































































@import '@design';
@import '@styleMixins';

.catering-form-desc {
  @extend .poppins--font;

  @include font-size(24px);
}
